export class ApiRoute {
    //lead-routes
    public static GET_LEADS: string = "/lead/getLeads";
    public static CREATE_LEAD: string = "/lead/createLead";
    public static GET_LEAD: string = "/lead/getLead";
    public static UPDATE_LEAD: string = "/lead/updateLead";
    public static DELETE_LEAD: string = "/lead/deleteLead";
    public static SEND_RETAINER_AGREEMENT = "/lead/sendRetainerAgreementEmail";
    public static CONVERT_TO_CUSTOMER = "/lead/convertToCustomer"
    public static UPDATE_LEAD_INTAKE: string = "/lead/updateLeadIntake";
    public static SEND_INTAKE_FORM: string = "/lead/sendIntakeForm";
    public static CONVERT_TO_BENEFICIARY = "/lead/convertToBeneficiary"
    public static CREATE_QUICKBOOK_CUSTOMER = "/lead/createQuickBookCustomer"
    public static UPDATE_INTAKE: string = "/lead/updateIntake";
    public static VALIDATE_LEAD_INTAKE_FORM: string = "/lead/validateIntakeForm";
    public static GET_LEAD_FOR_INTAKE: string = "/lead/getLeadByIdForIntake";
    public static GET_TENANT_ID = "/lead/getTenantId";
    public static CREATE_ESIGN_FORM = "/lead-document/esign/createDocument";
    public static GET_ESIGN_FORM = "/lead-document/getDocument";
    public static UPDATE_ESIGN_FORM = "/lead-document/esign/updateDocument";
    public static SAVE_LEAD_DOCUMENT = "/lead-document/";
    public static DELETE_LEAD_DOCUMENT = "/lead-document/deleteDocument/"
    public static GET_LEADS_DASHBOARD = '/lead/dashboard'
    public static GET_ESIGN_ANONYMOUS_TOKEN = '/lead-document/esign/anonymous-token/'
    public static EXCHANGE_ESIGN_ANONYMOUS_TOKEN = '/lead-document/esign/exchange-token'
    public static UPLOAD_LEADS = '/lead/uploadleads'

    //admin-config-routes
    public static GET_TEMPLATE_BY_MODULE_NAME: string = "/template/getTemplates?moduleName=";
    public static GET_LOOKUPS_BY_MODULE_NAME: string = "/lookup/getLookups";
    public static GET_LOOKUPS_PUBLIC_BY_MODULE_NAME: string = "/lookup/getLookupsForPublic?moduleName=";
    public static GET_ROLES: string = "/role";
    public static GET_PERMISSIONS: string = "/permission";
    public static GET_ALL_MODULES: string = "/lookup/getModules";
    public static UPDATE_MODULE: string = "/lookup/updateModule";
    public static CREATE_MODULE: string = "/lookup/createModule";
    public static GET_ALL_LOOKUPS: string = "/lookup/getAllLookups"
    public static GET_ALL_MASTER_LOOKUPS: string = "/lookup/getAllMasterLookups"

    public static GET_ACTIVE_MODULES: string = "/lookup/getActiveModules"
    public static GET_LOOKUPBY_ID: string = "/lookup/getLookupById";
    public static GET_MASTER_LOOKUPBY_ID: string = "/lookup/getMasterLookupById";

    public static UPDATE_LOOKUP: string = "/lookup/updateLookup";
    public static CREATE_LOOKUP: string = "/lookup/createLookup";

    public static UPDATE_MASTER_LOOKUP: string = "/lookup/updateMasterLookup";
    public static CREATE_MASTER_LOOKUP: string = "/lookup/createMasterLookup";
    public static GET_MODULE_BY_LOOKUP = "/lookup/getModulesByLookupId";
    public static GET_LAWFIRM = "/lawfirm/getLawfirmDetails";
    public static CREATE_LAWFIRM = "/lawfirm/createLawfirm";
    public static GET_DOC_TEMPLATES = "/documentTemplate/getDocTemplates/";
    public static FILTER_DOC_TEMPLATES = "/documentTemplate/getDocTemplates/filter";
    public static CREATE_DOC_TEMPLATE = "/documentTemplate/createDocTempate";
    public static GET_DOC_TEMPLATE_BY_ID = "/documentTemplate/getDocTempateById/";
    public static UPDATE_DOC_TEMPLATE = "/documentTemplate/updateDocTempate/";
    public static DELETE_DOC_TEMPLATE = "/documentTemplate/deleteDocTempate/";
    public static CREATE_CONFIG: string = "/lookup/createConfig";
    public static GET_CONFIG_BYNAME: string = "/lookup/getConfigByName/fedex";
    public static GET_AUTH_TOKEN: string = "/lookup/getAuthToken";
    public static GET_CONFIG_BYNAME_QUICKBOOKS: string = "/lookup/getConfigByName/QuickBooksAccount";
    public static REFRESH_QB_ACCESS_TOKEN: string = "/lookup/refreshQbAccessToken";
    public static SAVE_QUICKBOOKDATA_ITEMS = "/lookup/saveQuickBookDataItems";
    public static GET_PDF_TEMPLATE_BY_TYPE = "/lookup/getPdfTemplate";
    public static GET_CASE_TO_TEST = "/lookup/getCaseToTest";

    public static CREATE_CASE_CONFIG = "/caseConfig/createCaseConfig";
    public static GET_ALL_CASE_CONFIG = "/caseConfig/all";
    public static GET_CASE_CONFIG_BY_CASE = "/getAllCaseConfigByType";
    public static GET_CASE_CONFIG_LOOKUPS = "/getCaseConfigLookups";




    public static CREATE_INVOICE_CONFIG: string = "/lookup/createInvoiceConfig";
    public static GET_INVOICE_CONFIG: string = "/lookup/getInvoiceConfig";
    public static GET_ALL_INVOICE_CONFIG: string = "/lookup/allConfigs"
    public static GET_INVOICE_CONFIG_BY_ID: string = "/lookup/getInvconfig";
    public static DELETE_INVOICE_CONFIG: string = "/lookup/deleteInvoiceConfig"
    public static GET_QBCONFIG_BYNAME: string = "/lookup/getConfigByName";


    public static CREATE_DISCOUNT_CONFIG: string = "/case/createDiscountConfig";
    public static GET_DISCOUNT_CONFIG: string = "/case/getDiscountConfig"
    public static DELETE_DISCOUNT_CONFIG: string = "/case/deleteDiscountConfig"
    public static GET_CUST_CONFIG: string = "/case/getcustDiscount"
    public static GET_DISCOUNT_BY_ID: string = "/case/getDiscount"



    public static CREATE_INVOICE: string = "/case/invoice/add";
    public static GET_ALL_INVOICES = '/case/invoice/all';
    public static GET_INVOICE = "/case/getInvoice";
    public static GET_DELETE_INVOICES = '/case/invoice/delete';
    public static VOID_QB_INVOICE = '/case/invoice/void';
    public static GET_QUICKBOOK_INVOICE= '/case/quickBook/invoice'

    public static GET_INVOICE_PAYMENTS = '/case/payments'

    //customer-routes
    public static CUSTOMER: string = "/customer/";
    public static CONTACT: string = "/contact";
    public static DOCUMENT: string = "/document";
    public static PETITION: string = "/petition";
    public static PERM: string = "/perm";


    //document-routes
    public static SAVE_DOCUMENT: string = "/document/uploads";
    public static GET_SIGNED_DOCUMENT: string = "/document/getSignedDocument?filename=";
    public static GET_SIGNED_UPLOAD_DOCUMENT: string = "/document/getSignedUploadFileLink?filename=";
    public static GET_BLOB_DOCUMENT: string = "/document/getDocument?filename=";
    public static DELETE_DOCUMENT: string = "/document/deleteDocument";
    public static FILL_PDF: string = "/document/fillpdf"
    public static SAVE_GENERATED_DOCUMENT: string = "/document/save-generated-document";
    public static MERGE_CASE_DOCUMENTS: string = "/document/merge-case-documents";

    //beneficiary-routes
    public static SAVE_BENEFICIARY: string = "/save-beneficiary";
    public static UPDATE_BENEFICIARY: string = "/update-beneficiary/";
    public static GET_BENEFICIARIES: string = "/get-beneficiaries";
    public static GET_BENEFICIARIES_ALL: string = "/get-all-beneficiaries";
    public static GET_BASIC_BENEFICIARIES: string = "/get-beneficiaries-basic";
    public static GET_BENEFICIARY: string = "/get-beneficiary/";
    public static GET_CUSTOMER_LIST: string = "/get-customer-list";
    public static SAVE_BENEFICIARY_EDU: string = "/education";
    public static SAVE_BENEFICIARY_WORK_EXP: string = "/work-experience";
    public static SAVE_PASSPORT: string = "/passport-detail";
    public static SAVE_VISA: string = "/visa-detail";
    public static SAVE_I94: string = "/i94-detail";
    public static SAVE_LCA: string = "/lca-detail";
    public static SAVE_H1B: string = "/h1b-detail";
    public static SAVE_F1: string = "/f1-detail";
    public static SAVE_GC: string = "/gc-detail";
    public static SAVE_I485: string = "/i485-detail";
    public static SAVE_BD: string = "/bd-detail";
    public static DELETE_BENEFICIARY = "/delete-beneficiary/";
    public static SAVE_H4_DETAILS = "/save-h4-details";
    public static GET_LCA_DETAIL: string = "/get-lca-detail/";
    public static GET_VISA_DETAIL: string = "/get-visa-detail/";
    public static GET_BENE_EMAIL = "/get-beneficiary-email/";
    public static GET_SPOUSE_EMAIL = "/get-spouse";

    public static GET_BENE_CASE_DOCUMETNS = "/documents"

    //user-routes
    public static GET_USER_ROLES: string = "/user-management/User/";
    public static CREATE_USER: string = "/user-management/create-user";
    public static UPDATE_USER: string = "/user-management/update-user";
    public static GET_ALL_USERS: string = "/user-management/get-lawtrax-users";
    public static GET_USERS: string = '/user-management/get-users';
    public static GET_ALL: string = '/user-management/get-all';
    public static HANDLE_LOGIN: string = "/user-management/handle-login";
    public static GET_TEAMS: string = "/user-management/get-all-teams";
    public static CREATE_TEAM: string = "/user-management/create-team";
    public static PASSWORD_RESET: string = "/user-management/password-reset";
    public static USER_PASSWORD_RESET: string = "/user-management/user-password-reset";
    public static GET_USER_BY_EMAIL: string = "/user-management/get-user";
    public static CHECK_USER_BY_EMAIL: string = "/user-management/checkUser";

    public static SEND_NOTIFICATION_MAIL: string = "/user-management/sendNotificationEmail";
    public static GET_USER_PERMISSIONS: string = "/user-management/getUserPermission?userId=:userId";
    public static GET_INLINE_USER_PERMISSIONS: string = "/user-management/getInlineUserPermission?userId=:userId";
    public static UPDATE_USER_PERMISSIONS: string = "/user-management/updateUserPermission";
    public static GET_ROLE_PERMISSIONS: string = "/user-management/getRolePermission?role=:role";
    public static UPDATE_ROLE_PERMISSIONS: string = "/user-management/updateRolePermission";

    //case-routes
    public static GET_ALL_CASES: string = "/case/cases";
    public static GET_CASE: string = "/case/get-case";
    public static GENERATE_PDF: string = "/case/generate-pdf"
    public static GET_CASE_BENEFICIARIES = '/beneficiary';
    public static GET_CASE_BENEFICIARIES_ADDRESS = '/beneficiary/address/all';
    public static NUMBER_CASE_ATTORNEY = '/case/numberOfCaseByAtrny';
    public static GET_REPORT_CONFIG = '/report/getReportConfig';
    public static GET_REPORT_DATA = '/report/executeReportQuery';
    public static GET_PERM_REPORT_BENEFICIARIES = '/report/getBeneficiariesForPERMReport';

    public static GET_CASE_COMPANY = '/customer'
    public static SAVE_POST = '/case';
    public static SAVE_CASE = '/case';
    public static LINK_CASE_DOCS = "/case/link-case-documents";
    public static GET_LINKED_CASE_DOCS = "/case/get-linked-case-documents";
    public static SAVE_CASE_DOCUMENTS = "/case/save-case-document";
    public static CASE_PUSH_NOTIFICATIONS = "/case/push-notifications";
    public static UPDATE_STAGE_DETAILS = "/case/Task";
    public static CASE_ALERTS = "/case/alerts";
    public static UPDATE_ALERT = "/case/alert";
    public static DELETE_CASE = "/case/delete-case";
    public static ENTITY_DOCUMENTS = "/case/entity-documents";
    public static REORDER_CASE_DOCUMETS = "/case/reorder-case-document";
    public static GET_CASE_BY_CASE_NUMBER = "/case/get-case-info-by-case-number";
    public static GET_MERGE_DOCS = "/case/get-merged-documents";
    public static SAVE_TRACK_NUMBER = "/case/saveTrackNumber";
    public static GET_ALERTS_BY_CUSTOMER = '/case/alerts'
    public static GET_CASE_DASHBOARD = '/case/dashboard'
    public static GET_CASE_ALERTS_FILTER = '/case/alertsfilter'
    public static UPDATE_PETITION_STATUS = '/case/updatePetionStatus'
    public static Get_H1B_DETAIL = '/case/get-h1b-info-by-beneficiary-id';
    public static SEND_CREATE_CASE_EMAIL = "/case/sendCreateCaseEmail";
    public static UPDATE_CASE_NUMBER = "/case/updateCaseNumber";
    public static GET_CASE_BY_BENEFICIARY: string = "/case/get-case-by-beneficiary";
    public static CREATE_ESIGN_FORM_CASE = "/case-document/esign/createDocument";
    public static GET_ESIGN_FORM_CASE = "/case-document/getDocument";
    public static UPDATE_ESIGN_FORM_CASE = "/case-document/esign/updateDocument";
    public static SAVE_MAPPER = "/case/saveMapper";

    //lca-routes
    public static SAVE_CASE_LCA = '/case/lca';
    public static GET_CASE_LCA = '/case/lca';
    public static GET_ALL_LCA = '/case/cases';
    public static DELETE_H1B = '/delete-h1b';



    public static GET_ALL_CUSTOMER_CASES: string = "/customer/cases";
    public static GET_CUSTOMER_CASE: string = "/customer/get-case";
    public static GET__CUSTOMER_CASE_COMPANY = '/customer'
    public static SAVE_CUSTOMER_POST = '/customer/case';
    public static SAVE__CUSTOMER_CASE = '/customer/case';
    public static SAVE_CUSTOMER_SIGNATORY = '/contact';
    public static GET_ALL_CUSTOMERS = 'all'
    public static GET_CUSTOMER_BASIC = 'basic'
    public static GET_CASE_CUSTOMER_BASIC = '/customer/basic/all'
    public static GET_CASE_CUSTOMER_BASIC_ADDRESS = '/customer/address/all'
    public static GET_CUSTOMER_DOCUMENT = 'getDocument';
    public static EXECUTE_REPORT_QUERY = 'executeReportQuery';

    public static SEND_CASE_EMAIL = "/case/sendCreateCaseEmail";
    public static GET_CUSTOMER_BASIC_BY_ID = 'basicById/';
    public static GET_CUSTOMER_EMAIL = "get-customer-email/";
    public static GET_CUST_BENE_EMAIL = "get-beneficiary-email/";


    public static SAVE_CUSTOMER_CASE_LCA = '/customer/lca';
    public static GET_CUSTOMER_CASE_LCA = '/customer/lca';
    public static GET_ALL_CUSTOMER_LCA = '/customer/cases';
    public static GET_CUSTOMER_DASHBOARD = '/customer/dashboard'
    public static GET_CUSTOMER_ALERTS_FILTER = '/customer/alertsfilter'
    public static CUSTOMER_UPDATE_ALERT = "alert";
    public static CUSTOMER_UPLOAD = "uploadCustomer"

    public static GET_CUSTOMER_BENEFICIARIES = '/beneficiaries';
    public static SAVE_CUSTOMER_BENEFICIARY = '/beneficiary';
    public static GET_CUSTOMER_BENFICIARY = '/beneficiary';
    public static UPDATE_CUSTOMER_BENFICIARY = '/beneficiary';
    public static GET_BENEFICIARY_DASHBOARD = '/beneficiary/dashboard'
    public static GET_BENEFICIARY_ALERTS_FILTER = '/beneficiary/alertsfilter'
    public static BENEFICIARY_UPDATE_ALERT = "/alert";
    public static BENEFICIARY_UPLOAD = "/uploadBene"



    public static ATTORNEY = '/attorney/';
    public static GETATTORNEY = '/getAccountDetails';
    public static GETUSER = '/getAccountDetailsFromUser';
    public static UPDATEPROFILE = '/updateAccountForNonAttorneyUser';


    public static GET_EMAIL_TEMPLATES = '/email/getTemplates';
    public static GET_EMAIL_SIGNATURES = '/email/getSignatures';
    public static GET_EMAIL_SMTPS = '/email/getsmtps';
    public static POST_EMAIL = '/email/sendEmail';
    public static POST_SMTP = '/email/createsmtp';
    public static POST_SIGNATURE = '/email/createsignature';
    public static UPDATE_SMTP = '/email/updatesmtp';
    public static UPDATE_SIGNATURE = '/email/updatesignature';
    public static DELETE_SMTP = '/email/deletesmtp';
    public static DELETE_SIGNATURE = '/email/deletesignature';
    public static TEST_SMTP = '/email/testSmtp';
    public static UPDATE_SENT_EMAIL = '/email/updateSentMail';
    public static SENT_MAILS = '/email/sentmails ';
    public static SENT_INVOICE = '/email/sendInvoice';


    public static CREATE_CALENDER = '/calendar/event';
    public static GET_CALENDAR_EVENTS = '/calendar/all';
    public static DELETE_CALENDAR_EVENTS = '/calendar/delete';
    public static UPDATE_CALENDAR_EVENT = '/calendar/update';
    public static SNOOZE_CALENDAR_EVENT = '/calendar/snooze';

    public static CREATE_PERM = '/add';
    public static DELETE_COMPANY = '/deletecompany/'
    public static CREATE_BATCH = '/batch/add';
    public static CREATE_JOB = 'batch/job';
    public static GET_ALL_PERM_CUSTOMERS = '/all';
    public static GET_ALL_BATCHES_BY_CUSTOMER = '/batch/all/';
    public static GET_BATCH_BY_ID = '/batch/';
    public static DELETE_BATCH_BY_ID = '/batch/delete/'


    public static GET_ALL_JOBS_BY_BATCH = '/batch/jobs/';
    public static GET_JOB_BY_ID = '/job/';
    public static DELETE_JOB_BY_ID = '/job/delete/';
    public static JOB_CASES_BY_ID = '/job/cases/'

    public static GET_JOB_DOCUMENTS = '/documents/';
    public static UPDATE_JOB_DOCUMENT = '/updatedoc/';
    public static DELETE_JOB_DOCUMENT = '/deletedoc/'
    public static GET_JOB_BY_CUSTOMERID = 'getJobsByBatchId/'





}